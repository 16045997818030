import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);
export default new Vuetify({
    theme: {

        themes: {
          light: {
            primary: '#1a237e',
            secondary: '#1a237e',
            accent: '#8c9eff',
            error: '#b71c1c',
            background: '#E0E0E0',
          },
        },
      },
    icons: {
        iconfont: 'mdiSvg'|| 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
      },
});
