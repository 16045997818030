<template >
  <v-container class="fill-height hero" fluid tag="section">
    <v-row  justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-alert shaped color="error" v-if="isError" icon="mdi-account-alert">
          {{ errorMessage }}
        </v-alert>

        <v-card class="elevation-12">
          <v-form ref="form" v-model="valid">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Login</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="login"
                prepend-icon="mdi-email"
                required
              ></v-text-field>

              <v-text-field
                id="password"
                label="password"
                name="password"
                prepend-icon="mdi-lock"
                v-model="password"
                type="password"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :disabled="!valid"
                @click="postLogin"
                v-if="showSubmitButton"
                >Sumbit</v-btn
              >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                v-if="isLoading"
              ></v-progress-circular>
            </v-card-actions>
          </v-form>
        </v-card>
        <!-- <v-btn color="primary" text @click="$router.push('ragistration')"
          >New User? Ragister here</v-btn
        > -->
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .hero {
    background: url('https://firebasestorage.googleapis.com/v0/b/myfirestore-94aaa.appspot.com/o/hotelv1.PNG?alt=media&token=6d9252ff-c0a4-4b0b-93ce-cf57e0b508cb');
    background-size: cover;
    height: 100vh;
  }
</style>
<script>
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase/firebaseInit";
// import { collection, doc, getDoc } from "firebase/firestore";

export default {
  name: "loginGlobal",
  data() {
    return {
      valid: true,
      email: "",
      password: "",
      responseDTO: {
        status: "",
        reason: "",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      errorMessage: "",
      isError: false,
      isLoading: false,
      showSubmitButton: true,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    async postLogin() {
      try {
        this.isLoading = true;
        this.showSubmitButton = false;
        const user = await auth.signInWithEmailAndPassword(
          this.email,
          this.password
        );
        const userRef =  db.collection("users").doc(user.user.uid);
        const userDoc = await userRef.get();
        const userSnap =  userDoc.data();

        const userDetails = {
          email: userSnap.email,
          name: userSnap.contact_name,
          roles: userSnap.roles,
          doc_id: userDoc.ref.id,
        };
        let menus = [];
        for (let index = 0; index < userSnap.roles.length; index++) {
          const roleName = userSnap.roles[index];
          const menuSnap = await db
            .collection("menus")
            .doc(roleName)
            .collection("submenu").orderBy("order","asc")
            .get();
          menuSnap.forEach( (doc) => {
            const data =  doc.data();
            menus.push(data);
          });
        }
        this.isLoading = false;
        // query: { t: new Date().getTime() },
        this.$store.dispatch("setUserDetails", userDetails);
        this.$store.dispatch("setMenu", menus);

        if (userDetails.roles.includes("admin")) {
          await this.$router.push({ path: "updateAdmin" });
        } else if (userDetails.roles.includes("hotel")) {
          userSnap.hotel_doc_id = userRef.id;
          userSnap.hotelUser = true;
          this.$store.dispatch("setHotelInsightData", userSnap);
          this.$router.push({ path: "hotelInsight" });
        } else {
          await this.$router.push({ path: "about" });
        }
      } catch (error) {
        this.errorMessage = error;
        this.isError = true;
        this.isLoading = false;
        this.showSubmitButton = true;
      }
    },
  },
};
</script>