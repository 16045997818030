import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '@/store';


Vue.use(VueRouter)

const routes = [
  // { path: "/", name: 'main', component: () => import("../components/HelloWorld.vue") },
  { path: "/home", name: 'home', component: () => import("../components/HelloWorld.vue") },
  { path: "/about", name: 'about', component: () => import("../views/AboutView.vue") ,meta:{requiresAuth:true}},
  { path: "/updateHotel", name: 'updateHotel', component: () => import("../views/UpdateHotel.vue") },
  { path: "/updateAdmin", name: 'updateAdmin', component: () => import("../views/UpdateAdmin.vue") },
  { path: "/createUser", name: 'createUser', component: () => import("../views/CreateUsers.vue") },
  { path: "/hotelInsight", name: 'hotelInsight', component: () => import("../views/HotelInsight.vue") },
  { path: "/downloadReport", name: 'downloadReport', component: () => import("../views/DownloadVMReport2.vue") },
  { path: "/userDownloadReport", name: 'userDownloadReport', component: () => import("../views/UserDownloadVMReport.vue") },
  { path: "/graphHotel", name: 'graphHotel', component: () => import("../views/AddHotelGraphData.vue") },
  { path: "/graphChart", name: 'graphChart', component: () => import("../views/GraphChart.vue") },
  { path: "/createAdmin", name: 'createAdmin', component: () => import("../views/CreateAdmin.vue") },
  { path: "/commentsComponents", name: 'commentsComponents', component: () => import("../components/Comments.vue") },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {
//     if (!store.getters.loggedIn && to.path !== '/login') {
//         next('/login');    
//     } else if (to.path !== '/login') {
//         next(store.getters.auth.views.find(element => element.view === to.path) != null);
//     } else {
//         next(true);
//     }
// });
export default router
