 <template>
  <v-app id="inspire" v-if="isUserLoggedIn">
    <v-navigation-drawer v-model="drawer" app color="secondary" dark width="200">
      <v-list nav>
        <v-list-item v-for="(item, i) in getMenu" :key="i" :to="item.path">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
            <!-- <v-icon>mdi-account-alert</v-icon> -->
            <!--  -->
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary" elevation="20" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ getUserDetails.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-item>
        <SignoutHotel />
      </v-toolbar-item>
    </v-app-bar>
    <v-card color="background" height="100%">
    <v-main>
      <transition >
        <router-view />
      </transition>
    </v-main>
  </v-card>
    <!-- <chat-app></chat-app> -->
  </v-app>
  <v-app v-else>
    <login-global></login-global>
  </v-app>
</template>

<script>
// import ChatApp from "@/views/Chat.vue";
import LoginGlobal from "@/components/LoginGlobal.vue";
import SignoutHotel from "@/components/SignoutHotel.vue";
export default {
  name: "mainHotel",
  components: {
    // ChatApp,
    LoginGlobal,
    SignoutHotel,
  },
  data: () => ({
    drawer: false,
    isLoggedIn: false,
  }),
  computed: {
    getMenu() {
      // return this.menu;
      return this.$store.getters.getMenues;
    },
    isUserLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    getUserDetails() {
      return this.$store.getters.getUserDetails;
    },
  },
  methods: {},
};
</script>