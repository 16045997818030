import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDvtWx7tTxaEBDqotOMSRfBDihX_nJ9KZA",
  authDomain: "vm-management-75cef.firebaseapp.com",
  projectId: "vm-management-75cef",
  storageBucket: "vm-management-75cef.appspot.com",
  messagingSenderId: "263130262673",
  appId: "1:263130262673:web:61f6c50da147a5cf89a330",
  measurementId: "G-R7N0B6MQ20"
};
  
  // Initialize Firebase
  export const firebaseApp = firebase.initializeApp(firebaseConfig);

  export const db = firebaseApp.firestore();
  export const auth = firebaseApp.auth();