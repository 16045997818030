import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
  },
  state: {
    token: "",
    isLoggedIn:false,
    userDetails: {
        email: "",
        mobile: "",
        address: "",
        type: "",
        name: "",
        roles:[],
        doc_id:"",
    },
    menus: [],
    hotelInsightData:{},
    commentRequest:{},
},
actions:{
    setUserDetails({ commit }, userDetails) {
        commit('SET_USER', userDetails);
    },
    setMenu({ commit }, menus) {
        commit('SET_MENUS', menus);
    },
   
    setHotelInsightData({ commit }, data) {
        commit('SET_HOTELINSIGHT', data);
    },
   
    setCleanUser({ commit }, userDetails) {
        commit('CLEAN_USER', userDetails);
    },
    setCommentsRequest({ commit }, commentsRequest) {
        commit('SET_COMMENTS_REQUEST', commentsRequest);
    },
   
},
mutations:{
    SET_USER(state, data) {
        state.userDetails = data
        state.token = data.token
        state.isLoggedIn = true
    },
    CLEAN_USER(state, data) {
        state.userDetails = data
        state.token = data.token
        state.isLoggedIn = false
    },
    SET_MENUS(state, data) {
        state.menus = data
    },
    SET_HOTELINSIGHT(state, data) {
        state.hotelInsightData = data
    },
    SET_COMMENTS_REQUEST(state, data) {
        state.commentRequest = data
    },
},
getters: {
    isUserLoggedIn(state) {
        return state.isLoggedIn;
    },
    getUserDetails(state) {
        return state.userDetails;
    },
    getHotelInsightData(state) {
        return state.hotelInsightData;
    },
    getMenues(state) {
        return state.menus;
    },
    setCommentsRequest(state) {
        return state.hotelInsightData;
    },
}
})

export default store
