<template>
  <v-container>
    <v-btn color="acccent" @click="logout" icon>
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { auth } from "../firebase/firebaseInit";
import "firebase/auth";
export default {
  name: "signoutHotel",

  data: () => ({
    message: "",
  }),
  methods: {
    async logout() {
      try {
        await auth.signOut();
        await this.$store.dispatch("setCleanUser", {});
        await this.$router.push("login");
      } catch (error) {
        this.message = error;
      }
    },
  },
};
</script>
